import React from 'react';
import './App.css';
import { AiSearch } from './DocsAI';

function App() {
  return (
    <div className="App">
      <AiSearch />
      <header className="App-header">
        <h1>DocsGenie AI Widget Demo</h1>
        <p>Click on the search bar above to see magic 🎉</p>
       
      </header>
    </div>
  );
}

export default App;
